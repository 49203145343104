body {
  background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

h2, h3 {
  color:black;
  font-family: 'Courier New';
  font-weight: 700;
}

.App {
  text-align: left;
  max-width: 600px;
  margin: 0px auto auto;
}

.section {
  text-align: left;
  max-width: 600px;
  margin: 0px auto auto;
}

.account {
  text-align: right;
  max-width: 600px;
  margin: 20px auto 4px;
}

.bshadow {
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
}
